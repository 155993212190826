<template>
<div class="w">
  <div class="crumbs">
    <router-link to="/">首页</router-link>
    <span>/</span>
    便民服务
    <span>/</span>
    <span style="color: #07C160">业务系统</span>
  </div>
  <ul class="list">
    <li class="item" v-for="item of list" :key="item.id">
      <div class="img">
        <img :src="item.imgurl">
      </div>
      <div class="content">
        <div class="title">{{item.subject}}</div>
        <div class="intro">{{item.body}}</div>
        <div class="btn">
          <a :href="item.url" target="_blank">进入系统</a>
        </div>
      </div>
    </li>
  </ul>
  <div class="pagebox">
    <el-pagination
      layout="prev, pager, next"
      :page-size="10"
      :current-page="pageNo"
      @current-change="pageChange"
      :total="total">
    </el-pagination>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { ElPagination } from 'element3'
import { yncySystem } from '../api'

export default defineComponent({
  name: 'PeriodicalList',
  components: {
    ElPagination
  },
  setup () {
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)

    const getListData = () => {
      yncySystem({ pageNo: pageNo.value, pageSize: 12 }).then(res => {
        total.value = res.result.total
        list.value = res.result.records
      })
    }
    onMounted(() => {
      getListData()
    })

    const pageChange = (page) => {
      pageNo.value = page
      getListData()
    }

    return { list, pageNo, total, pageChange, getListData }
  }
})
</script>

<style lang="scss" scoped>
.list{
  width: 1160px;
  margin: 0 auto 60px auto;
}
.item{
  padding: 30px 10px;
  border-bottom: 1px solid #dddddd;
  display: flex;
}
.img{
  width: 250px;
  margin-right: 30px;
  img{
    width: 100%;;
  }
}
.content{
  flex: 1;
}
.title{
  margin-bottom: 30px;
  font-size: 22px;
  width: 800px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.intro{
  line-height: 22px;
  height: 88px;
  margin-bottom: 30px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.btn{

  a{
    border: 1px solid #07C160;
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: block;
    color: #07C160;

    &:hover{
      border-color: #07C160;
      background: #07C160;
      color:#ffffff;
    }
  }
}
</style>
